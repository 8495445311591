import React from 'react';
import { useQuery } from 'react-apollo';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import { Components } from '../ui/ContentRenderer';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId },
  });

  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageRenderer
      result={result}
      startPageComponents={Components}
      ProductGrid={<div />}
    />
  );
};

export default StartPage;
